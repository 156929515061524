/* eslint-disable react/jsx-props-no-spreading */
import {
  initialize,
  LDClient,
  LDContext,
  LDEvaluationDetail,
  LDFlagSet,
  LDFlagValue,
} from 'launchdarkly-js-client-sdk';
import { LDProvider as LDProviderFromReactSDK } from 'launchdarkly-react-client-sdk';
import React from 'react';

import { buildLaunchDarklyClientSideProviderConfig } from '@assured/utilities/src/launchDarklySetup';

interface AssuredLDContextValue {
  ldClient: LDClient;
}

const AssuredLDContext = React.createContext<AssuredLDContextValue | null>(
  null,
);

export const useLDClient = () => {
  const context = React.useContext(AssuredLDContext);
  return context?.ldClient;
};

interface Props {
  children: React.ReactNode;
  client: LDClient;
}

export const LDProvider = ({ client, children }: Props) => {
  const value: AssuredLDContextValue = React.useMemo(() => {
    return {
      ldClient: client,
    };
  }, [client]);

  return (
    <AssuredLDContext.Provider value={value}>
      {children}
    </AssuredLDContext.Provider>
  );
};

// This should be used when a component is referencing the LD React SDK provider
// and cannot be refactored to use the above provider (AssuredLDContext)
// i.e. because it is defined outside the adjuster package, e.g. Sidekick
export const LDFallbackProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const ldClient = useLDClient();

  return (
    <LDProviderFromReactSDK {...ldConfig} ldClient={ldClient}>
      {children}
    </LDProviderFromReactSDK>
  );
};

const ldConfig = buildLaunchDarklyClientSideProviderConfig({
  clientSideID: process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID,
  appSlug: 'adjuster',
  appName: 'Adjuster',
});

class MockLDClient {
  private flags: LDFlagSet = {};
  private ready = false;
  private onReady: (() => void) | undefined = undefined;

  constructor() {
    this.ready = false;
    this.getFlags().then(flags => {
      this.flags = flags;
      this.onReady?.();
      this.ready = true;
    });
  }

  private async getFlags() {
    const response = await fetch('http://localhost:4000/flags');
    const data = await response.json();
    return data;
  }

  on(key: string, callback: (...args: any[]) => void, context?: any): void {
    if (key === 'ready') {
      this.onReady = callback;
      if (this.ready) {
        callback();
      }
      return;
    }
    if (key === 'change') {
      return;
    }
    console.warn('MockLDClient: event handlers not implemented for:', key);
  }

  async identify() {
    return this.flags;
  }

  variation(key: string, fallback: LDFlagValue) {
    if (key in this.flags) return this.flags[key];
    return fallback;
  }

  waitUntilGoalsReady(): Promise<void> {
    throw new Error('MockLDClient: Method not implemented.');
  }
  waitUntilReady(): Promise<void> {
    throw new Error('MockLDClient: Method not implemented.');
  }
  waitForInitialization(): Promise<void> {
    throw new Error('MockLDClient: Method not implemented.');
  }
  getContext(): LDContext {
    throw new Error('MockLDClient: Method not implemented.');
  }
  flush(onDone?: (() => void) | undefined): Promise<void> {
    throw new Error('MockLDClient: Method not implemented.');
  }
  variationDetail(key: string, defaultValue?: any): LDEvaluationDetail {
    throw new Error('MockLDClient: Method not implemented.');
  }
  setStreaming(value?: boolean | undefined): void {
    throw new Error('MockLDClient: Method not implemented.');
  }
  off(key: string, callback: (...args: any[]) => void, context?: any): void {
    throw new Error('MockLDClient: Method not implemented.');
  }
  track(key: string, data?: any, metricValue?: number | undefined): void {
    throw new Error('MockLDClient: Method not implemented.');
  }
  allFlags(): LDFlagSet {
    throw new Error('MockLDClient: Method not implemented.');
  }
  close(onDone?: (() => void) | undefined): Promise<void> {
    throw new Error('MockLDClient: Method not implemented.');
  }
}

const isolateApp = process.env.REACT_APP_ISOLATE;

export const setup = (
  config: ReturnType<typeof buildLaunchDarklyClientSideProviderConfig>,
) => {
  if (isolateApp) {
    return new MockLDClient();
  }

  const { clientSideID, context, options } = config;
  const ldClient = initialize(clientSideID, context, options);

  return ldClient;
};
